import React from "react";
import styled from "styled-components";

const lineAppImage = require("../images/LINE_SOCIAL_Square.png");

const Div = styled.div`
  background: #00c510;
  border-radius: 12px;
  overflow: hidden;

  a {
    display: flex;
    align-items: center;
    width: 185px;
    color: #e7f8ec;
    text-decoration: none;
  }

  img.line-logo {
    width: 60px;
  }

  .text-area {
    padding: 4px 8px;
    font-size: 12px;
    p {
      line-height: 14px;
    }
  }
`;

const LineButtonLink = () => {
  return (
    <Div>
      <a href="http://line.me/ti/p/%40421wuqzc" target="_blank">
        <img src={lineAppImage} alt="LINE@" className="line-logo" />
        <div className="text-area">
          <p>LINE@はじめました</p>
          <p>休診や予防接種など</p>
          <p>情報発信します</p>
        </div>
      </a>
    </Div>
  );
};

export default LineButtonLink;
