import React from "react";
import styled from "styled-components";
import "react-slideshow-image/dist/styles.css";
import { Fade } from "react-slideshow-image";

const fadeProperties = {
  duration: 5000,
  transitionDuration: 500,
  indicators: true,
};

const Wrapper = styled.div`
  overflow: hidden;
`;

const images = [
  require("../images/photos/top/002.jpg"),
  require("../images/photos/top/032.jpg"),
  require("../images/photos/top/067.jpg"),
  require("../images/photos/top/025.jpg"),
  require("../images/photos/top/003.jpg"),
  require("../images/photos/top/007.jpg"),
  require("../images/photos/top/027.jpg"),
  require("../images/photos/top/021.jpg"),
  require("../images/photos/top/031.jpg"),
  require("../images/photos/top/013.jpg"),
  require("../images/photos/top/068.jpg"),
];

const SlideShow = () => (
  <Wrapper>
    <Fade {...fadeProperties}>
      {images.map((src, index) => {
        return (
          <div
            key={src + index}
            style={{
              backgroundImage: `url(${src})`,
              height: 600,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        );
      })}
    </Fade>
  </Wrapper>
);

export default SlideShow;
