import React from "react";
import SubTitle from "./subtitle";
import ShadowCard from "./shadow-card";
import styled from "styled-components";
import fetch from "isomorphic-fetch";
import TimeTable from "./time-table";
import NewsItem from "./news-item";
import { ClosedDaysLabel } from "./closed-days-label";

const Div = styled.div`
  .news-item {
    white-space: pre-wrap;
  }
`;

interface State {
  news: [string, string][];
}

class News extends React.Component<{}, State> {
  constructor(p: any) {
    super(p);
    this.state = {
      news: []
    };
  }
  async componentWillMount() {
    const URL =
      "https://script.google.com/macros/s/AKfycbzLCRfiUJOBHRqwqo8htjFdjSLiuyvjMjmyeqqQ6lvi4OtVMtc/exec";
    const res = await fetch(URL);
    const text = await res.text();
    this.setState({
      news: JSON.parse(text)
    });
  }
  render() {
    return (
      <>
        {this.state.news.length > 0 ? (
          <Div>
            <ShadowCard>
              <SubTitle>お知らせ</SubTitle>
              <h3 style={{fontSize: 24, marginBottom: 8}}>診療時間変更のお知らせ</h3>
              <p>2023年6月3日(土)より下記の時間帯に診療時間が変更になります。</p>
              <TimeTable />
              <ClosedDaysLabel />
              <ul style={{marginLeft:0,listStyle:"none"}}>
                {this.state.news.map((cells, index) => {
                  const date = new Date(cells[0]);
                  const split = cells[1].split("\n");
                  const title = split[0];
                  split.shift();
                  const description = split.join("\n");
                  return (
                    <li key={index} style={{marginTop:20}}>
                      <NewsItem title={title} description={description} date={date} />
                    </li>
                  );
                })}
              </ul>
            </ShadowCard>
          </Div>
        ) : null}
      </>
    );
  }
}

export default News;
