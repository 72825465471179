import React from "react";
import styled from "styled-components";
import SubTitle from "./subtitle";
import GoogleMap from "./google-map";

const Div = styled.div`
  .explain {
    padding: 16px;

    .route {
      margin-top: 8px;

      li {
        list-style: square;
      }
    }
  }
`;

const Access = () => {
  return (
    <Div>
      <SubTitle>アクセス</SubTitle>
      <div className="explain">
        <p>〒834-0063 八女市本村691-1</p>
        <ul className="route">
          <li>
            <p>黒木町・筑後市方面より</p>
            国道442号線&nbsp;福島中学校前交差点を八女市街方面（八女市役所や福岡銀行八女支店方向）へ入ってすぐ右側
          </li>
          <li>
            <p>久留米・広川方面より</p>
            国道3号線を下って柳病院前の本村北信号を右手斜めに入り、直進して福島中学校前信号を超えてすぐ右側
          </li>
        </ul>
      </div>
      <GoogleMap />
    </Div>
  );
};

export default Access;
