import React from "react";
import styled from "styled-components";
const doctorqubeLinkImage = require("../images/yoyaku155_73b.png");
const A = styled.a`
  display: block;
  background: #ff9500;
  width: 185px;
  height: 53px;
  text-align: center;
  border-radius: 12px;
  overflow: hidden;

  img {
    display: inline-block;
  }
`;
const DoctorqubeButtonLink = () => {
  return (
    <A href="https://ssc6.doctorqube.com/obuchi-jibika/" target="_blank">
      <img
        src={doctorqubeLinkImage}
        alt="ドクターキューブ予約システム"
        width={155}
        height={73}
      />
    </A>
  );
};
export default DoctorqubeButtonLink;
