import React, { useEffect, useState } from "react";
import LineButtonLink from "./line-button-link";
import DoctorqubeButtonLink from "./doctorqube-button-link";
import styled from "styled-components";

const Div = styled.div`
  transition: bottom 0.3s ease;
  > * {
    margin-top: 4px;
  }
`;

let beforeScrollTop = 0;

const FloatPanel = () => {
  const [bottom, setBottom] = useState(4);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      const container = document.querySelector("html");
      if (container) {
        if (container.scrollTop < beforeScrollTop) {
          setBottom(4);
        } else {
          setBottom(-130);
        }
        beforeScrollTop = container.scrollTop;
      }
    });
  }, []);
  return (
    <Div
      style={{
        position: "fixed",
        bottom,
        right: 4,
        zIndex: 1
      }}
    >
      <DoctorqubeButtonLink />
      <LineButtonLink />
    </Div>
  );
};

export default FloatPanel;
