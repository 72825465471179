import React, { useState } from "react";

interface Props {
  title: string;
  description: string;
  date: Date;
}

export default ({ title, description, date }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <button
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        style={{
          backgroundColor: "transparent",
          border: "none",
          cursor: "pointer",
          outline: "none",
          padding: 0,
          appearance: "none",
          textAlign: "left",
        }}
      >
        <p>{`${date.getFullYear()} ${
          date.getMonth() + 1
        }/${date.getDate()}`}</p>
        <span
          dangerouslySetInnerHTML={{ __html: title }}
          style={{
            textDecoration: "underline",
            marginLeft: 8,
          }}
        />
      </button>
      {isOpen && (
        <p
          dangerouslySetInnerHTML={{ __html: description }}
          style={{ whiteSpace: "pre-wrap", marginLeft: 8 }}
        />
      )}
    </>
  );
};
