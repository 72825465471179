import React from "react";

import Layout from "../components/layout";
import Section from "../components/section";
import TimeTableWrapper from "../components/time-table-wrapper";
import SlideShow from "../components/slideshow";
import BasicInformation from "../components/basic-information";
import News from "../components/news";
import Access from "../components/access";
import FloatPanel from "../components/float-panel";

const IndexPage = () => (
  <>
    <Layout isFloatHeader={true}>
      <SlideShow />
      <Section>
        <News />
      </Section>
      <Section>
        <TimeTableWrapper />
      </Section>
      <Section>
        <Access />
      </Section>
      <Section>
        <BasicInformation />
      </Section>
      <FloatPanel />
    </Layout>
  </>
);

export default () => <IndexPage />;
