import React from "react";

const GoogleMap = () => (
  <div>
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3337.9090632178923!2d130.5563144152823!3d33.216480180841586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3541a8d5fdf77bf9%3A0x62ce7c782adf0e71!2z44CSODM0LTAwNjMg56aP5bKh55yM5YWr5aWz5biC5pys5p2R5p2J55S677yW77yZ77yR!5e0!3m2!1sja!2sjp!4v1565478104429!5m2!1sja!2sjp"
      frameBorder="0"
      style={{
        border: 0,
        width: "100%",
        height: 600
      }}
      allowFullScreen
    />
  </div>
);

export default GoogleMap;
